import { observer } from "mobx-react";
import * as React from "react";
import { Banner } from "../../shared/components/banner/Banner";
import { List } from "../../shared/components/list/List";
import { PageTitle } from "../../shared/components/pageTitle/PageTitle";
import { Title } from "../../shared/components/title/Title";
import { ProductNestViewModel } from "./ProductNestViewModel";
import { InfoElement } from "./infoElement/InfoElement";
import { ProductCard } from "./productCard/ProductCard";

export const ProductNestView = observer(({ model }: { model: ProductNestViewModel }) => {
  const { title, description, products, listInfo } = model;

  const handleClose = () => {
    console.log("close");
  };

  const handleClick = () => {
    console.log("click");
  };

  return (
    <PageTitle title={title} description={description}>
      <Banner
        heading="Viktig informasjon"
        text="Denne tjenesten vil være oppe til 31/12-2024. Det er ikke lenger mulig å kjøpe tilgang, men eksisterende brukere vil ha tilgang ut året."
        onClose={handleClose}
        buttonText="Ok"
        variant="info"
        handleClick={handleClick}
      />
      <article className="ProductNest">
        <div className="ProductNest__inner">
          <div className="ProductNest__heading">
            <div className="ProductNest__info">
              <Title level={1} className="ProductNest__title">
                {title}
              </Title>
              <p className="ProductNest__description">{description}</p>
            </div>
            <List
              className="ProductNest__infoList"
              singleItemClassName="ProductNest__infoListElement"
              items={listInfo}
              renderItem={info => {
                return <InfoElement value={info.value} />;
              }}
            />
          </div>
          <List
            className="ProductNest__productList"
            singleItemClassName="ProductNest__productListElement"
            items={products}
            renderItem={product => {
              return (
                <ProductCard
                  hasMoreThanOneCourse={product.courses.length > 1}
                  courseSlug={product.courses.length === 1 ? product.courses[0].slug : ""}
                  slug={product.slug}
                  image={product.cardImage}
                  targetGroup={product.targetGroup}
                  title={product.title}
                />
              );
            }}
          />
        </div>
      </article>
    </PageTitle>
  );
});
