import React from "react";
import "./Banner.css";
import { Button } from "../button/Button";

interface BannerProps {
  heading: string;
  text: string;
  onClose: () => void;
  buttonText: string;
  variant: "link" | "button" | "info";
  handleClick?: () => void;
  isAuthenticated?: boolean;
}

export const Banner: React.FC<BannerProps> = ({
  heading,
  text,
  onClose,
  buttonText,
  variant,
  handleClick,
  isAuthenticated,
}) => {
  return (
    <div className="Banner">
      {variant !== "info" && (
        <Button onClick={onClose} className="Banner__crossButton" type="circle">
          X
        </Button>
      )}
      <div className="Banner__headingText">
        <h2>{heading}</h2>
        <p>{text}</p>
      </div>
      <div className="Banner__buttonAndLinkContainer">
        {variant === "link" && (
          <a href="https://qudos.no" target="_blank" rel="noreferrer">
            {buttonText}
          </a>
        )}
        {variant === "button" && !isAuthenticated && (
          <Button onClick={handleClick} size="medium">
            {buttonText}
          </Button>
        )}
      </div>
    </div>
  );
};
