import { observer } from "mobx-react";
import * as React from "react";
import { ContentView } from "react-mvvm";
import { ActivityIndicator } from "../../shared/components/activityIndicator/ActivityIndicator";
import { Banner } from "../../shared/components/banner/Banner";
import { useCourseColor } from "../../useCourseColor";
import { PaymentSidePanel } from "../shared/paymentDialog/PaymentSidePanel";
import { CourseViewModel } from "./CourseViewModel";

export const CourseView = observer(({ model }: { model: CourseViewModel }) => {
  const { color } = model.courseStructure;
  useCourseColor(color);

  return (
    <div className="CourseView">
      <Banner
        heading="Viktig informasjon"
        text="Denne tjenesten vil være oppe til 31/12-2024. Det er ikke lenger mulig å kjøpe tilgang, men eksisterende brukere vil ha tilgang ut året."
        onClose={() => {}}
        buttonText="Ok"
        variant="info"
        handleClick={() => {}}
      />
      <ActivityIndicator className="CourseView__loader" isLoading={model.isLoading}>
        <ContentView content={model.childPage} />
        {model.paymentSidePanelViewModel && <PaymentSidePanel modal={model.paymentSidePanelViewModel} />}
      </ActivityIndicator>
    </div>
  );
});
